import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataCell, DataRow, DataTable, Spinner, Pagination } from '@cd/sdds-common-components-react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import './CommunicationHistoryDataTable.scss';
import DateTimeSeperator from "../DateTimeSeperator";
import PositionData from "../Position";
import HistoricalDataVariablesDialog from '../HistoricalDataVariables';
import TimeDelayIndicator from '../TimeDelayIndicator';
import { getColumnsConfig } from '../../../helpers/communicationHistoryService';
import { utcFormatFromString } from '../../../helpers/timeFormatter';
import ColumnFormatter from '../DynamicColumnFormatter';
import { updateColumnSelection } from '../../../redux/store/historicalDataVariables';


interface CommunicationHistoryDataTablePropTypes {
  MessageList: Array<any>
  MessageKeyList: HistoricalDataResponseType[]
  onSortData?: (columnId: string, sortDirection: string) => void
  onRemoveColumn?: (columnId: string) => void
  paginationReset: boolean;
}

const CommunicationHistoryDataTable = ({ MessageList, MessageKeyList, onSortData, paginationReset }: CommunicationHistoryDataTablePropTypes) => {

  const { t } = useTranslation();
  const { isLoading } = useAppSelector<CommunicationStateType>(state => state.communicationHistoryStore);
  const currentstatusMessage = "currentstatus";
  const positionMessage = "position";
  const functionindicationMessage = "functionindication";
  const { selectedColumns } = useAppSelector(state => state.historicalDataStore);
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState(selectedColumns.length > 0 ? selectedColumns : getColumnsConfig(t).columns);
  const [currentPage, setCurrentPage] = useState(1);
  const [removedColumn, setremovedColumn] = useState('');
  let pageSize = 20;


  useEffect(() => {
    setCurrentPage(1)
  }, [paginationReset])

  useEffect(() => {
    dispatch(updateColumnSelection(columns))
  }, [columns])

  const onColumnsSelectionChange = (selectedColumns: CommunicationColumnConfigType[], unselectedColumns: CommunicationColumnConfigType[]) => {
    //add or remove new selections, remove any duplication before saving to store
    setColumns(columns.filter(item => !unselectedColumns.find(column => item.columnid === column.columnid)).concat(selectedColumns).filter((v, i, a) => a.findIndex(v2 => (v2.columnid === v.columnid)) === i))
  }

  const handleRemoveColumn = (columnId: string) => {
    setColumns(columns.filter(p => p.columnid.toLowerCase() !== columnId.toLowerCase()));
    setremovedColumn(columnId)
  }

  const handlePaginationData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    //Pagination reset to default filtered list lower than first page
    if (firstPageIndex > MessageList.length) {
      setCurrentPage(1)
    }
    return MessageList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, 3, pageSize, MessageList]);

  const onResetDefaultColumn = () => {
    setColumns(getColumnsConfig(t).columns);
  }
  return <>
    <DataTable
      title={`${t("")}`}
      columns={columns}
      onSort={onSortData}
      onRemoveColumn={handleRemoveColumn}
      actionBar={
        <>
          <HistoricalDataVariablesDialog MessageKeyList={MessageKeyList} onColumnsSelectionChange={onColumnsSelectionChange} onRemovedColumn={removedColumn} onResetDefaultColumn={onResetDefaultColumn} />
        </>
      } >
      <>
        {isLoading && <div className='loader'><Spinner /></div>}
        {!isLoading && MessageList && handlePaginationData.map((row, index) => {
          return <DataRow key={`row-${index}`}>
            {columns?.map((col: CommunicationColumnConfigType, colIndex: number) => {
              switch (col.columnid) {
                case 'messageType':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row?.messageType === currentstatusMessage && <span title={`${t('TS_core:currentstatus')}`} className="marker current-status"></span>}
                    {row?.messageType === positionMessage && <span title={`${t('TS_core:position')}`} className="marker position"></span>}
                    {row?.messageType === functionindicationMessage && <span title={`${t('TS_core:functionindication')}`} className="marker function-indication"></span>}
                  </DataCell>
                case 'gpsTimestamp':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row.messageType === positionMessage && row.gpsTimestamp && <><DateTimeSeperator inputDateTime={row.gpsTimestamp} /></>}

                  </DataCell>
                case 'messageTimestamp':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row.messageTimestamp && <><DateTimeSeperator inputDateTime={row.messageTimestamp} /></>}
                    {row.messageType === positionMessage && row.gpsTimestamp && <TimeDelayIndicator preTime={row.gpsTimestamp} postTime={row.messageTimestamp} />}
                  </DataCell>
                case 'timeSaved':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row.timeSaved && <DateTimeSeperator inputDateTime={row.timeSaved} />}
                    {row.timeSaved && row.messageTimestamp && <TimeDelayIndicator preTime={row.messageTimestamp} postTime={row.timeSaved} />}

                  </DataCell>
                case 'ignition':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {(row.messageType === functionindicationMessage) && ([1,true].indexOf(row.variables?.ignition?.value) > -1  && <span title={`${t("TS_core:IgnitionIsOn")}`} style={{ color: "#1DAB8B" }}>ON</span>)}
                    {(row.messageType === functionindicationMessage) && ([0,false].indexOf(row.variables?.ignition?.value) > -1  && <span title={`${t("TS_core:IgnitionIsOff")}`} style={{ color: "#FF2340" }}>OFF</span>)}
                  </DataCell>
                case 'speed':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row.messageType === currentstatusMessage && row.convertedTachoSpeed}
                    {row.messageType === positionMessage && row.convertedGpsSpeed}

                  </DataCell>
                case 'odometerICL':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row.messageType === currentstatusMessage && row.convertedOdometerICL}
                  </DataCell>
                case 'odometer':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row.messageType === currentstatusMessage && row.convertedOdometer}
                  </DataCell>
                case 'odometerDistance':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row.messageType === currentstatusMessage && row.convertedOdometerDistance}
                  </DataCell>
                case 'position':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {(row.messageType === positionMessage) && row.gpsLatitude && row.gpsLongitude &&
                      <>
                        <PositionData latitude={row.gpsLatitude} longitude={row.gpsLongitude} />
                      </>

                    }
                  </DataCell>
                case 'processingLog':
                  return <DataCell key={`col-index-${colIndex}`}>
                    <ul>
                      {row.ProcessingLog && row.ProcessingLog?.map((item: any) => {
                        return <li key={`sub-${index}`}> {item?.processStep}: {row.messageType === positionMessage ? utcFormatFromString(item?.timeStamp) : utcFormatFromString(item?.timestamp)}</li>
                      })
                      }
                    </ul>
                  </DataCell>
                default:
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row[col.columnid] ? <ColumnFormatter data={row[col.columnid]} /> : (row.variables ? (row.variables[col.columnid] && <ColumnFormatter data={row.variables[col.columnid].value} />) : "")}
                  </DataCell>
              };
            })}
          </DataRow>;
        })
        }
      </>
    </DataTable>
    <div className="sdds-col-md-12 sdds-headline-06 vertical-center">
      {handlePaginationData.length > 0 && <Pagination currentPage={currentPage} totalCount={MessageList.length} pageSize={pageSize} onPageChange={(page: any) => setCurrentPage(page)} />}
    </div>

  </>
};

export default CommunicationHistoryDataTable;
