import React, { ChangeEvent, useState } from 'react';
import InfoIconWithLabel from '../../../components/InfoIconWithLabel';
import { useTranslation } from 'react-i18next';
import { CustomModal, DataCell, DataRow, DataTable, Icons, Pagination, Spinner } from '@cd/sdds-common-components-react';
import { getDataTableConfig } from '../../../helpers/rtcUnitLogExportService';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc'
import './rtc-unit-log.scss';
import { downloadLogFile } from '../../../adapters/MPMessengerAdapter';
//import { error } from 'console';

dayjs.extend(utcPlugin);

type RTCLogPropTypes = {
  unitMessages: Array<UnitMessageType>
  isLoading: boolean
  hasError: boolean
  errorMessage: string
  isNotified: boolean | null
  telematicsUnitEquipmentInfo: TelemeticsEquipmentInformationType | null
  rtcLogContents: string
  areContentsLoading: boolean
  showContents: boolean
  downloadUrl: string
  currentLogContentsMessageId: string
  showSSHConfirm: boolean
  showRtcLogConfirm: boolean
  currentPageIndex: number
  totalRowsCount: number;
  onShowSSHConfirm: (isOpenSsHRequest: boolean) => void
  onHideSSHConfirm: () => void
  onShowRTCLogConfirm: () => void
  onHideRTCLogConfirm: () => void
  onRequestRTCLogs: () => void
  onOpenCloseSSH: (openFlag: boolean) => void
  onRequestLogMessageContents: (messageId: string) => void,
  onHideContents: () => void,
  onEmailNotificationCheckChange: (e: ChangeEvent<HTMLInputElement>) => void
  onPageIndexChange: (page: number) => void
  onRefresh?: () => void
}

// eslint-disable-next-line no-empty-pattern
const RTCLogPage = ({ unitMessages, isLoading, isNotified, telematicsUnitEquipmentInfo, currentPageIndex, totalRowsCount,
  rtcLogContents, areContentsLoading, showContents, downloadUrl, currentLogContentsMessageId, showSSHConfirm, showRtcLogConfirm,
  onHideSSHConfirm, onHideRTCLogConfirm, onOpenCloseSSH, onRequestRTCLogs, onRequestLogMessageContents, onShowSSHConfirm, onShowRTCLogConfirm,
  onHideContents, onEmailNotificationCheckChange, onPageIndexChange, onRefresh }: RTCLogPropTypes) => {

  const { t } = useTranslation();
  const columns: any = getDataTableConfig();
  const [isOpenSsHRequest, setIsOpenSshRequest] = useState<boolean>(false);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentUnitMessage,setCurrentUnitMessage] = useState<UnitMessageType>();

  const onLoadContents = (logsMessageId: string) => {
    onRequestLogMessageContents(logsMessageId);
  }

  const onConfirmRequestRtcLog = () => {
    onRequestRTCLogs();
    onHideRTCLogConfirm();
  }
  const onOpenCloseSSHPortClick = (openFlag: boolean) => {
    setIsOpenSshRequest(openFlag);
    onShowSSHConfirm(openFlag);

  }
  const onConfirmToggleSshPort = () => {
    onOpenCloseSSH(isOpenSsHRequest);
    onHideSSHConfirm();
  }
  const onDownloadLogfilefromS3 = (logsMessageId: string) => {
    downloadLogFile(logsMessageId).then((response) => {
      if (response.data && response.data !== "") {
        fetchAndmodiyLogFile(response.data, logsMessageId);
      }
    })
  }

  const fetchAndmodiyLogFile = (signedUrl: string, logsMessageId:string) => {
    fetch(signedUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error("Error: Unable to retrieve the file");
        }
        console.log('Type of data:', typeof response);
        return response.arrayBuffer();  // Get the raw data as an ArrayBuffer
      })
      .then(data  => {
        const byteArray = new Uint8Array(data);
        var contentType =  'application/octet-stream' //as binary
        // Check if the first three bytes are either [0x00, 0x01, 0x00] or [0x00, 0x02, 0x00]
        // For train 16.0.1 and later
        if(byteArray[1] === 0x02){
          contentType = 'application/x-tar';  //tar-archeiving
        }
        else {// for ealier releases then train 16.0.1
          if (byteArray[3] === 0x1F && byteArray[4] === 0x8b) { //.gz
          contentType = 'application/gzip';  // Gzip file
          }
        }
        // Remove the first 3 bytes
        const modifiedByteArray = data.slice(3);
        const blob = new Blob([modifiedByteArray], { type: contentType } );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        //link.download = filename;
        link.setAttribute('download', `${logsMessageId}-rtc-logs`);
        link.click()
    })
    .catch (error => {
        console.error('Error downloading or processing file:', error);
    })
  };


  return <>
    <div className='rtc-log-page'>
      {isLoading && <div className='spin-container'><Spinner /></div>}
      {telematicsUnitEquipmentInfo && !telematicsUnitEquipmentInfo.isSupported &&
        <InfoIconWithLabel labelText={`${telematicsUnitEquipmentInfo.rtcType} version ${telematicsUnitEquipmentInfo.rtcVersion} does not support this feature`} />}
      {telematicsUnitEquipmentInfo && telematicsUnitEquipmentInfo.isSupported &&
        <div className=' rtc-log-container sdds-container-fluid'>
          <InfoIconWithLabel labelText={t("TS_core:AllTimeIsUTCTime")} />

          <div className="sdds-row sdds-u-pt2 sdds-no-padding">
            <div className='sdds-col-md-8'>
              <div className="sdds-row">
                <div> <button className='sdds-btn sdds-btn-primary sdds-btn-sm sdds-u-mt1' onClick={onShowRTCLogConfirm} >{t("TS_core:RequestRTCLog")}</button></div>

                <div className='sdds-u-pt2 sdds-u-ml2'>
                  <div className="sdds-checkbox-item">
                    <input className="sdds-form-input" type="checkbox" name="enable-email-notification" id="enable-email-notification" onChange={onEmailNotificationCheckChange} checked={isNotified === true} disabled={isNotified === true} />
                    <label className="sdds-form-label" htmlFor="enable-email-notification">
                      {t("TS_core:EnableEmailNotification")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='sdds-col-md-4'>
              <div className="sdds-row flex-row">
                <div >
                  <button className='sdds-btn sdds-btn-secondary sdds-btn-sm' onClick={() => onOpenCloseSSHPortClick(true)}  >{t("TS_core:OpenSSHPort")}</button>
                </div>
                <div >
                  <button className='sdds-btn sdds-btn-secondary sdds-btn-sm' onClick={() => onOpenCloseSSHPortClick(false)} >{t("TS_core:CloseSSHPort")}</button>
                </div>
              </div>
            </div>
          </div>

          <div className='dt-container'>
            <DataTable
              columns={columns}
              title={t("TS_core:RequestHistory")}
              actionBar={<>
                {onRefresh && <button className="sdds-table__actionbar-btn" onClick={onRefresh} title={t("TS_core:Refresh")} disabled={isLoading}>
                  <Icons.RefreshIcon />
                </button>}
              </>}
            >
              {(!unitMessages || unitMessages.length === 0) && !isLoading && <DataRow cssClassName='sdds-body-02'>{t("TS_core:NoDataFound")}</DataRow>}
              {unitMessages && unitMessages.length > 0 && unitMessages.map((row: any, rowIndex: number) => {
                return <DataRow cssClassName='row' key={'row-' + rowIndex}>
                  {columns?.map((col: ColumnConfigType, colIndex: number) => {
                    if (col.columnid === 'timeSave') {
                      return <DataCell key={'col' + colIndex} cssClassName='cell' >{dayjs.utc(row[col.columnid]).format("YYYY-MM-DD HH:mm:ss")}</DataCell>;
                    } else if (col.columnid === 'action') {
                      return <DataCell key={'col' + colIndex} cssClassName='cell' >
                        {!areContentsLoading && row.typeCode === 399 && !row.hasDownloadableFile &&<button type='button' className='sdds-btn sdds-btn-secondary sdds-btn-sm' onClick={() => { setCurrentUnitMessage(row);onLoadContents(row.id) }} >{t("TS_temp:FetchLog")}</button>}
                        {row.typeCode === 399 && row.hasDownloadableFile && <button className="sdds-btn sdds-btn-sm sdds-btn-primary" onClick={() => { onDownloadLogfilefromS3(row.id) }}> {t("TS_core:DownloadLog")}</button>}
                        {row.id === currentLogContentsMessageId && areContentsLoading && <Spinner size='sm' />}
                      </DataCell>;
                    } else {
                      return <DataCell key={'col' + colIndex} cssClassName='cell'>{row[col.columnid]}</DataCell>;
                    }
                  })}
                </DataRow>;
              })}
            </DataTable>
            <Pagination currentPage={currentPageIndex} totalCount={totalRowsCount} pageSize={20} onPageChange={onPageIndexChange} />
          </div>
        </div>}
    </div>
    <CustomModal id='rtc-log-contents' title={t("TS_core:CommunicatorLog")}
      show={showContents} handleClose={onHideContents} size='md'
      actions={
        <>
          <a className="sdds-btn sdds-btn-sm sdds-btn-primary" href={downloadUrl} download="rtclog.txt">{t("TS_core:DownloadLog")}</a>

          <button
            type="button"
            className="sdds-btn sdds-btn-secondary sdds-btn-sm"
            onClick={onHideContents}
          >
            {t("TS_core:Close")}
          </button>
        </>} >
      {rtcLogContents.split("\n").map((textRow, index) => {
        return <div className={`sdds-body-02 ${index % 2 ? 'even' : 'odd'}`} key={index}>{textRow}</div>
      })}
    </CustomModal>
    <CustomModal id='confirm-ssh' title={t(`TS_core:ConfirmSSHPortTitle`)}
      show={showSSHConfirm} handleClose={onHideSSHConfirm} size='sm'
      actions={
        <>
          <button className="sdds-btn sdds-btn-primary sdds-btn-sm" onClick={() => { onConfirmToggleSshPort() }}>
            {t(`TS_core:Yes`)}
          </button>
          <button
            type="button"
            className="sdds-btn sdds-btn-secondary sdds-btn-sm"
            onClick={onHideSSHConfirm}
          >
            {t("TS_core:No")}
          </button>
        </>} >
      <div>{t("ConfirmSSHPortMsg", { OPERATION: `${isOpenSsHRequest ? t("TS_core:Open") : t("TS_core:Close")}` })}</div>
    </CustomModal>

    <CustomModal id='confirm-rtc-Logs' title={t(`TS_core:ConfirmRTCLogReqTitle`)}
      show={showRtcLogConfirm} handleClose={onHideRTCLogConfirm} size='sm'
      actions={
        <>
          <button className="sdds-btn sdds-btn-primary sdds-btn-sm" onClick={onConfirmRequestRtcLog}>
            {t(`TS_core:Yes`)}
          </button>
          <button
            type="button"
            className="sdds-btn sdds-btn-secondary sdds-btn-sm"
            onClick={onHideRTCLogConfirm}
          >
            {t("TS_core:No")}
          </button>
        </>} >
      <div>{t("TS_core:ConfirmRequestRTCLogMsg")}</div>
    </CustomModal>
  </>
}

export default RTCLogPage