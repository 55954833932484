import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '@cd/sdds-common-components-react';

const NoDataFoundMessage = () => {
  const { t } = useTranslation();

  return <>
    <div className="sdds-row">
      <div className="no-data-content info-icon">
        <Icons.InfoIcon />
        <span>
          {`${t('TS_core:NoDataFound')}. ${t('TS_core:OnlyPositionMessagesAreUsedForTheChartAndMap')}`}
        </span>
      </div>
    </div>
  </>
};

export default NoDataFoundMessage;

